<template>
  <q-btn
    unelevated
    no-caps
    icon="list_art"
    dense
    flat
    style="padding-right: 13px;font-weight: bold;"
    @click="handleClick('/orders')"
    class="order-button"
  >
    <q-badge
      :color="!this.orders ? 'transparent' : 'light-blue-6'"
      floating
      class="badge"
    >
      {{ this.orders || 0 }}
    </q-badge>
    <q-tooltip>{{ $t('Orders') }}</q-tooltip>
  </q-btn>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OrderNotification',
  data () {
    return {
      prefix: '',
      ordersLoading: true,
      orders: null
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  mounted () {
    if (this.appOptions.versionType !== 'simple') {
      this.prefix = '/outbound'
    }

    this.loadItems()
  },
  methods: {
    ...mapActions([
      'loadOrders'
    ]),
    handleClick (route) {
      this.$router.push(this.prefix + route)
    },
    loadItems () {
      const query = {
        page: 1,
        per_page: 25,
        filter: [
          { type: 'in', field: 'state', values: ['pending', 'confirmed', 'reserved'] }
        ]
      }

      return this.loadOrders(query)
        .then(({ totalItems }) => {
          this.ordersLoading = false
          this.orders = totalItems
        })
    }
  }
}
</script>

<style>
  .order-button .q-btn__content {
    margin: 0;
    padding: 0 0.5rem;
  }

  .order-button i {
    margin-left: 8px;
  }
</style>
