<template>
  <q-btn-dropdown
      unelevated
      no-caps
      dense
      flat
      :style="{ padding: '4px', display: 'flex', alignItems: 'center' }"
  >
    <template v-slot:label>
      <q-avatar class="bg-primary text-white">
        <span class="text-uppercase">{{ userInitials() }}</span>
        <q-badge class="status-dot"/>
      </q-avatar>
    </template>

    <div class="relative row no-wrap q-pa-md" style="display: block;margin: 0 auto;">
      <div class="avatar-container">
        <q-avatar class="bg-primary text-white">
          <span class="text-uppercase">{{ userInitials() }}</span>
          <q-badge class="status-dot" />
        </q-avatar>
        <span class="q-ml-sm text-bold" style="color: #616161!important;">{{ this.user.name }}</span>
      </div>

      <q-separator/>

      <div class="column" v-if="appOptions.versionType !== 'simple'">
        <div class="text-center">
          <q-btn
              v-close-popup
              color="grey-8"
              :label="$t('Entity Settings')"
              class="full-width q-mt-xs"
              flat
              align="left"
              icon="settings"
              size="sm"
              @click="openInfo"
          />
        </div>

        <div class="text-center">
          <q-btn
              v-close-popup
              flat
              align="left"
              icon="print"
              class="full-width q-mt-sm"
              size="sm"
              :label="$t('Printer settings')"
              color="grey-8"
              @click="openPrinterSettings"
          />
        </div>
      </div>

      <q-separator
          v-if="appOptions.versionType !== 'simple'"
          vertical
          inset
          class="q-mx-lg"
      />

      <div class="column items-center">
        <q-btn
            v-close-popup
            color="grey-8"
            :label="appOptions.versionType === 'simple' ? $t('My Profile') : $t('My Account')"
            flat
            align="left"
            icon="person"
            size="sm"
            class="full-width"
            @click="handleOpenProfile"
        />

        <q-btn
            v-if="!isClient && appOptions.versionType !== 'simple'"
            v-close-popup
            color="grey-8"
            :label="$t('open pro interface')"
            class="full-width q-mt-sm"
            flat
            align="left"
            size="sm"
            @click="goToProSite"
        />

        <q-btn
            v-if="appOptions.versionType === 'simple'"
            v-close-popup
            color="grey-8"
            :label="$t('Settings')"
            class="full-width q-mt-sm"
            flat
            align="left"
            icon="settings"
            size="sm"
            @click="openInfo"
        />

        <q-btn
            v-if="appOptions.versionType === 'simple'"
            v-close-popup
            flat
            align="left"
            icon="print"
            class="full-width q-mt-sm"
            size="sm"
            :label="$t('Printer settings')"
            color="grey-8"
            @click="openPrinterSettings"
        />

        <q-btn
            v-close-popup
            color="grey-8"
            :label="$t('Logout')"
            flat
            align="left"
            icon="exit_to_app"
            size="sm"
            class="full-width q-mt-sm"
            @click="handleLogout"
        />
      </div>
    </div>
  </q-btn-dropdown>

  <info-modal ref="infoModal"/>

  <printer-settings-modal ref="printerSettingsModal"/>

  <user ref="userModal"/>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import User from '../user/User'
import PrinterSettingsModal from '../modals/PrinterSettingsModal.vue'
import InfoModal from '../modals/InfoModal.vue'
import gravatar from 'gravatar'

// Services
import { UserService } from '../../services'

export default {
  name: 'UserDropdown',
  components: {
    User,
    PrinterSettingsModal,
    InfoModal
  },
  data () {
    return {
      isConnectedPrinterBg: false,
      locale: window.appOptions.locale,
      locales: window.appOptions.locales,
      theme: window.appOptions.theme,
      themes: window.appOptions.themes,
      printerSub: null
    }
  },
  computed: {
    ...mapGetters([
      'printer',
      'appOptions',
      'currentUser',
      'isClient'
    ]),
    connectedPrinterBg () {
      return this.isConnectedPrinterBg || (this.printer && this.printer.connected)
          ? 'green'
          : 'danger'
    },
    user () {
      return this.currentUser
    },
    username () {
      const text = this.user.username || this.user.email || ''
      const currentUsername = text.slice(0, 20)

      return currentUsername.length === text.length
          ? text
          : text + '...'
    }
  },
  mounted () {
    this.printerSub = this.$service.printer._printer.subscribe('stateChange', (isConnected) => {
      this.isConnectedPrinterBg = isConnected
    })
      console.log(this.user)
  },
  unmounted () {
    this.printerSub.unsubscribe()
  },
  methods: {
    ...mapMutations([
      'updateAppOptions'
    ]),
    openInfo () {
      this.$refs.infoModal.open()
    },
    openPrinterSettings () {
      this.$refs.printerSettingsModal.open()
    },
    handleDarkMode () {
      this.$q.dark.set(!this.$q.dark.isActive)
      localStorage.setItem('darkMode', JSON.stringify(this.$q.dark.isActive))
    },
    handleDebug (debug) {
      this.updateAppOptions({ debug })
    },
    handleLocale (locale) {
      this.locale = locale
      window.appOptions.locale = locale

      this.$q.cookies.set('locale', JSON.stringify(locale))
      localStorage.setItem('appOptions', JSON.stringify(window.appOptions))

      this.$i18n.locale = window.appOptions.locale.locale
    },
    handleTheme ({ theme }) {
      this.updateAppOptions({ theme })
      this.theme = theme
    },
    goToProSite () {
      const user = JSON.parse(localStorage.getItem('user'))
      const win = window.open(`${window.appOptions.defaultServer}/users/login/terminal?token=${user.refresh_token}`, '_blank')
      win.focus()
    },
    handleOpenProfile () {
      this.$refs.userModal.openCloseProfile()
    },
    handleLogout () {
      this.$channels.user.unsubscribe()
      this.$centrifuge.disconnect()

      UserService.logout()
      this.$eventBus.update('rules', { user: null, app: 'security' })
    },
    userInitial() {
      return this.user.name.charAt(0)
    },
    userInitials() {
      return this.user.name
        .split(' ')
        .map(name => name.charAt(0))
        .join('')
    },
  }
}
</script>

<style>
  .avatar-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .q-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1976D2;
    color: white;
    font-size: 38px!important;
    font-weight: bold;
  }

  .q-avatar.small {
    font-size: 25px!important;
  }

  .status-dot {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    position: absolute;
    background-color: #21BA45;
    bottom: -2px;
    right: -2px;
  }
</style>