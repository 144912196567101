<template>
  <q-header
    v-if="isEnabledHeader && isEnabledOnMobile"
    elevated
    :class="`bg-animated ${classes({ prefix: 'bg', invert: true })} ${classes({ prefix: 'text', invert: false })}`"
    height-hint="64"
  >
    <q-toolbar class="GNL__toolbar">
      <q-btn
        dense
        flat
        round
        icon="menu"
        @click="openCloseSidebar"
        class="q-mr-sm"
      />

      <q-toolbar-title shrink class="row items-center no-wrap">
        <router-link to="/">
          <q-img
            :src="logo"
            fit="contain"
            width="140px"
            height="50px"
          />
        </router-link>
      </q-toolbar-title>

      <q-space />

      <q-input v-if="0" class="GNL__toolbar-input" outlined dense v-model="search" color="shadow-1" placeholder="Search for topics, locations & sources">
        <template v-slot:prepend>
          <q-icon v-if="search === ''" name="search" />
          <q-icon v-else name="clear" class="cursor-pointer" @click="search = ''" />
        </template>
        <template v-slot:append>
          <q-btn
              flat
              dense
              round
              aria-label="Menu"
              icon="arrow_drop_down"
          >
            <q-menu anchor="bottom end" self="top end">
              <div class="q-pa-md" style="width: 400px">
                <div class="text-body2 text-grey q-mb-md">
                  Narrow your search results
                </div>

                <div class="row items-center">
                  <div class="col-3 text-subtitle2 text-grey">
                    Exact phrase
                  </div>
                  <div class="col-9 q-pl-md">
                    <q-input dense v-model="exactPhrase" />
                  </div>

                  <div class="col-3 text-subtitle2 text-grey">
                    Has words
                  </div>
                  <div class="col-9 q-pl-md">
                    <q-input dense v-model="hasWords" />
                  </div>

                  <div class="col-3 text-subtitle2 text-grey">
                    Exclude words
                  </div>
                  <div class="col-9 q-pl-md">
                    <q-input dense v-model="excludeWords" />
                  </div>

                  <div class="col-3 text-subtitle2 text-grey">
                    Website
                  </div>
                  <div class="col-9 q-pl-md">
                    <q-input dense v-model="byWebsite" />
                  </div>

                  <div class="col-12 q-pt-lg row justify-end">
                    <q-btn flat dense no-caps color="grey-7" size="md" style="min-width: 68px;" label="Search" v-close-popup />
                    <q-btn flat dense no-caps color="grey-7" size="md" style="min-width: 68px;" @click="onClear" label="Clear" v-close-popup />
                  </div>
                </div>
              </div>
            </q-menu>
          </q-btn>
        </template>
      </q-input>

      <q-space />

      <div class="q-gutter-sm row items-center no-wrap" :class="`${classes({ prefix: 'text', invert: false })}`" v-if="isEnabledFeatures">

        <order-notification />

        <shortcuts />

        <client-balance v-if="!isAdminOrSupervisior && (appOptions.versionType !== 'simple')" />

        <notifications-dropdown @notification="handleClick" />

        <user-dropdown />

      </div>
    </q-toolbar>
  </q-header>
  <printer-settings-modal ref="printerSettingsModal" v-if="appOptions.versionType === 'simple'"/>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import OrderNotification from './OrderNotification.vue'
import ClientBalance from '../client-balance/ClientBalance'
import NotificationsDropdown from './NotificationsDropdown'
import UserDropdown from './UserDropdown'
import Shortcuts from './Shortcuts.vue'
import PrinterSettingsModal from '../modals/PrinterSettingsModal.vue'

// Quasar
import { debounce } from 'quasar'

import useMode from '@/composables/mode';
import {UserService} from "@/apps/app/services";

export default {
  name: 'Header',
  emits: ['notification'],
  components: {
    OrderNotification,
    ClientBalance,
    NotificationsDropdown,
    UserDropdown,
    Shortcuts,
    PrinterSettingsModal
  },
  data () {
    return {
      isEnabledFeatures: true,
      isConnectedPrinterBg: false,
      printerSub: null
    }
  },
  computed: {
    ...mapGetters([
      'printer',
      'appOptions',
      'isOpenSupport',
      'currentUser'
    ]),
    connectedPrinterBg () {
      return this.isConnectedPrinterBg || (this.printer && this.printer.connected)
      ? 'green'
      : 'danger'
    },
    isAdminOrSupervisior () {
      return this.currentUser?.roles?.find(({ id }) => id === 3 || id === 29)
    },
    user () {
      return JSON.parse(localStorage.getItem('userData')) || {}
    },
    logo () {
      return window.appOptions.logoTop
    },
    isEnabledOnMobile () {
      if (window.innerWidth > 700) {
        return true
      }

      if (!this.$route.matched[0] || !this.$route.matched[0].props || !this.$route.matched[0].props) {
        return true
      }

      return !this.$route.matched[0].props.disableHeaderOnMobile
    },
    isEnabledHeader () {
      if (!this.$route.matched[0] || !this.$route.matched[0].props || !this.$route.matched[0].props.default) {
        return true
      }

      return !this.$route.matched[0].props.default.disableHeader
    }
  },
  setup() {
    const { classes } = useMode();

    return {
      classes,
    };
  },
  mounted () {
    this.resize()
    this.resize = debounce(this.resize.bind(this), 500)

    window.addEventListener('resize', this.resize)

    this.printerSub = this.$service.printer._printer.subscribe('stateChange', (isConnected) => {
      this.isConnectedPrinterBg = isConnected
    })
  },
  unmounted () {
    window.removeEventListener('resize', this.resize)

    this.printerSub.unsubscribe()
  },
  methods: {
    ...mapMutations([
      'openCloseSidebar'
    ]),
    resize () {
      this.isEnabledFeatures = window.innerWidth > 700
    },
    handleClick (data) {
      this.$emit('notification', data.data)
    },
    openPrinterSettings () {
      this.$refs.printerSettingsModal.open()
    },
    handleLogout () {
      this.$channels.user.unsubscribe()
      this.$centrifuge.disconnect()

      UserService.logout()
      this.$eventBus.update('rules', { user: null, app: 'security' })
    }
  }
}
</script>
